<template>
  <div class="layout-page">
    <v-row no-gutters class="mb-8 mt-3">
      <v-col cols="3"/>
      <v-col cols="6">
        <v-img width="" src='../assets/logos2m.svg' />
      </v-col>
      <v-col cols="3"/>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex align-center">
        <v-icon large color="white" @click="$emit('onBack')"
          >keyboard_arrow_left</v-icon
        >
        <span class="ml-2 mt-4">
          <p v-html="header" style="font-size:20px; color: white;" class="font-weight-medium"></p>
        </span>
        <v-spacer> </v-spacer>
        <v-btn v-if="receive" rounded @click="$emit('receive')" class="text-center" color="#2CD97B">
          <div>
            <div style="font-size:20px; color: white;" class="mr-1 font-weight-medium">รับเหรียญ</div>
          </div>
          <v-img class="w-4 h-4" src="../assets/receive-icon.svg"/>
        </v-btn>
      </v-col>
      <v-col cols="12" class="mt-5 px-3">
        <v-row no-gutters justify="center">
          <slot name="content"></slot>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Layout",
  props: {
    header: {
      type: String,
      default: "",
    },
    receive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.layout-page {
  height: 100vh;
  min-height: 500px;
  padding: 24px 8px;
  .p{
    color: white;
  }
}
</style>